import { Account } from 'project-domain/models/account';
import { accountCreateRepositories } from '~/repositories/account';

const createAccount = async (userId: string, account: Account, isNew = true) => {
  const res = await accountCreateRepositories.save({
    id: account.id,
    mode: 'all',
    new: isNew,
    body: { ...account, userId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res;
};

const readAccount = async (userId: string) => {
  const res = await accountCreateRepositories.getDetail({
    id: userId,
    mode: 'all',
  });

  if (res instanceof Error) {
    throw res;
  }
  return res;
};

export const accountCreateServices = {
  readAccount,
  createAccount,
};

import React from 'react';
import NotFound from '../pages/notfound/notfound';

const AppRouter: React.FC<{}> = () => {
  return (
    <div>
      <NotFound />
    </div>
  );
};
export default AppRouter;

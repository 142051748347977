import nanoid from 'nanoid';
import { map, prop, propEq, eqProps, pipe, not } from 'global-lib/ramda';
import { TLookupItem } from '../types';

export const searchLookupsItem = (master: TLookupItem[], keyword: string): TLookupItem[] =>
  keyword ? master.filter(({ value }) => new RegExp(`^(?=.*${keyword}).*$`).test(value)) : [];

export const getLookupItemValue = (v: TLookupItem): string => prop('value', v) || '';
export const toLookupItemValues = map(getLookupItemValue);
export const getLookupItemId = (v: TLookupItem): string => prop('id', v);
export const toLookupItemIds = map(getLookupItemId);
export const toLookupItem = (value: string): TLookupItem => ({ id: nanoid(), value });
export const toLookupItems = map(toLookupItem);
export const getLookupItemValueById = (master: TLookupItem[]) => (id: string): string =>
  master.find(propEq('id', id))?.value || '';
export const isLookupItem = (v: any): v is TLookupItem => typeof v.id === 'string' && typeof v.value === 'string';
export const isLookupItems = (v: any): v is TLookupItem[] => Array.isArray(v) && v.every(isLookupItem);
const isSelectedItem = (selectedItems: TLookupItem[]) => (item: TLookupItem): boolean =>
  selectedItems.some(eqProps('value', item));
export const excludeSelectedItems = (selectedItems: TLookupItem[], selectableItems: TLookupItem[]): TLookupItem[] =>
  selectableItems.filter(pipe(isSelectedItem(selectedItems), not));
export const canSelectValue = (selectedItems: TLookupItem[], text?: string): boolean =>
  !!text && text.length > 0 && !isSelectedItem(selectedItems)(toLookupItem(text));

import { getValidationErrorMessage } from 'global-lib/validation';

export const VALIDATION_ERROR_MESSAGE = {
  required: '必須入力項目が未入力です。',
  email: 'ご指定のメールアドレスは登録できません。',
  min: '入力できる最小値を超えています。',
  max: '入力できる最大値を超えています。',
  range: '入力できる範囲を超えています。',
  minLength: '正しい桁数を入力してください。',
  maxLength: '正しい桁数を入力してください。',
  rangeLength: '長さの最大最小を超過',
  string: '文字以外が入力されています。',
  array: '配列',
  number: '数字以外が入力されています。',
  validNumber: '数字以外が入力されています。',
  dateStr: '不正な日付が入力されています。',
  everyRequired: '配列の必須',
  everyMin: '配列の最大値を超過',
  everyMax: '配列の最小値を超過',
  everyRange: '配列の最大最小値を超過',
  everyMinLength: '最小の長さ超過',
  everyMaxLength: '最大の長さ超過',
  everyRangeLength: '長さの最大最小を超過',
  everyString: '型が不正',
  everyArray: '型が不正',
  everyNumber: '型が不正',
  everyValidNumber: '型が不正',
  everyDateStr: '型が不正',
  includes: 'マスタにないデータを選択',
  everyIncludes: 'マスタにないデータを選択',
};

export const CUSTOM_MESSAGE_CONFIG = {
  isValidDateRangeStr: '入力した日付の範囲が不正です。',
  isValidPriceRange: '入力した数値の範囲が不正です。',
  isInputInvalidStr: '正しい値を入力してください。',
  isImageExt: 'Please choose an image file',
};

export const getInvalidErrorMessage = getValidationErrorMessage(VALIDATION_ERROR_MESSAGE)(CUSTOM_MESSAGE_CONFIG);
export const getInvalidInputStr = '正しい値を入力してください。';
export const getTopErrorStr = '正しく入力されていない項目があります。ご確認のうえ、もう一度ご入力ください。';

import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../../store';

const getFeatureState = (state: AppState) => state.auths.authentication;

export const authSelector = {
  isAuthenticated: createSelector(getFeatureState, (state) => state.isAuthenticated),
  user: createSelector(getFeatureState, (state) => state.user),
  LoginedId: createSelector(getFeatureState, (state) => state.user.id),
  AccountId: createSelector(getFeatureState, (state) => state.user.accountId),
};

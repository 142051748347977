import React, { ComponentProps } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../atoms/Button/Button';

import cssStyles from './FormBtnSet.module.scss';
import { getClassNameCreator } from '~/presentation/helpers';

type TSetCssStylesKey = keyof Pick<
  typeof cssStyles,
  'formBtnSet' | 'alignRight' | 'alignLeft' | 'alignCenter' | 'noBorder' | 'dialogBtnSet' | 'btnOnlyBlock'
>;
type TLeftItemCssStylesKey = keyof Pick<
  typeof cssStyles,
  'item' | 'small' | 'borderTop' | 'borderTopDanger' | 'noBorder' | 'borderTopCancel' | 'backBtn'
>;
type TRightItemCssStylesKey = keyof Pick<
  typeof cssStyles,
  'item' | 'small' | 'borderTop' | 'borderTopDanger' | 'noBorder' | 'borderTopCancel' | 'backBtn'
>;
type ButtonStyles = ComponentProps<typeof Button>['styles'];
type TProps = {
  setStyles?: TSetCssStylesKey[];
  textLeft?: string;
  stylesLeft?: ButtonStyles;
  itemStylesLeft?: TLeftItemCssStylesKey[];
  disabledLeft?: boolean;
  pathLeft?: string;
  isBackLeft?: boolean;
  onClickLeft?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  textRight?: string;
  stylesRight?: ButtonStyles;
  itemStylesRight?: TRightItemCssStylesKey[];
  disabledRight?: boolean;
  pathRight?: string;
  onClickRight?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const getSetClassName = getClassNameCreator<TSetCssStylesKey>(cssStyles);
const getLeftItemClassName = getClassNameCreator<TLeftItemCssStylesKey>(cssStyles);
const getRightItemClassName = getClassNameCreator<TRightItemCssStylesKey>(cssStyles);

const FormBtnSet: React.FC<TProps> = ({
  setStyles = [],
  textLeft,
  stylesLeft,
  itemStylesLeft = [],
  disabledLeft,
  pathLeft,
  isBackLeft = false,
  onClickLeft,
  textRight,
  stylesRight,
  itemStylesRight = [],
  disabledRight,
  pathRight,
  onClickRight,
}) => {
  const history = useHistory();
  const goToBack = () => history.goBack();
  const goToPage = (path: string) => () => history.push(path);

  return (
    <div className={getSetClassName('formBtnSet', setStyles)}>
      {textLeft && (
        <div className={getLeftItemClassName('item', itemStylesLeft)}>
          <div className="btnWrap">
            <Button
              text={textLeft}
              styles={stylesLeft}
              disabled={disabledLeft}
              // eslint-disable-next-line no-nested-ternary
              onClick={isBackLeft ? goToBack : pathLeft ? goToPage(pathLeft) : onClickLeft}
            />
          </div>
        </div>
      )}
      {textRight && (
        <div className={getRightItemClassName('item', itemStylesRight)}>
          <div className="btnWrap">
            <Button
              text={textRight}
              styles={stylesRight}
              disabled={disabledRight}
              onClick={pathRight ? goToPage(pathRight) : onClickRight}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FormBtnSet;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isTried: false,
};
type TState = typeof initialState;

const _module = createSlice({
  name: '[store/ui/autoLogin]',
  initialState,
  reducers: {
    setTryFlag(state: TState, action: PayloadAction<void>) {
      return { ...state, isTried: true };
    },
  },
});

export const autoLoginModule = _module;

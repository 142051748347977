import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, tap, mergeMap } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { from } from 'rxjs';
import { News } from 'project-domain/models/news';
import { AppState } from '~/store';
import { homeEvent } from '~/presentation/events';
import { Logger } from '~/di';
import { errorActions } from '~/root/errorActions';
import { homeServices } from '~/application/services/home';
import { newsDetailModule } from '~/store/entities/newsDetail';

import { loadingModule } from '~/store/ui/loading';

type TLoadNewsDone = ReturnTypeExcludePromise<typeof homeServices.getNewsList>;
const ac = actionCreatorFactory('[listener/pages/home]');
const _actions = {
  loadNewsNext: ac<TLoadNewsDone>('_loadNewsNext'),
};

// epics
const loadNews: Epic<
  AnyAction,
  WrapAction<typeof asyncActionWithCallback> | WrapAction<typeof _actions.loadNewsNext>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(homeEvent.loadNews),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(homeServices.getNewsList()),
        previous: loadingModule.actions.on(),
        next: (v: ActionParameter<typeof _actions.loadNewsNext>) => _actions.loadNewsNext(v),
        error: (v: any) => errorActions.throwError(v),
        complete: loadingModule.actions.off(),
      }),
    ),
  );

const loadNewsDone: Epic<AnyAction, WrapAction<typeof newsDetailModule.actions.read>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.loadNewsNext),
    tap(({ payload }) => Logger.log(payload)),
    mergeMap(({ payload }) => {
      const news = payload.data as News[];
      return [newsDetailModule.actions.read(news)];
    }),
  );

export const homeEpics = combineEpics(loadNews, loadNewsDone);

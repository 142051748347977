import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogError from './DialogError';
import { errorSelector } from '~/application/selector/ui';
import { errorActions } from '~/root/errorActions';
//

const DialogErrorContainer: React.FC<{}> = () => {
  const { hasError, code, message } = useSelector(errorSelector.error);
  const dispatch = useDispatch();
  const close = useCallback(() => {
    dispatch(errorActions.clearError());
  }, [dispatch]);
  return <DialogError hasOpened={hasError} code={code} message={message} close={close} />;
};

export default DialogErrorContainer;

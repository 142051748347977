import actionCreatorFactory from 'typescript-fsa';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { Epic, combineEpics } from 'redux-observable';
import { AnyAction } from '@reduxjs/toolkit';
import { map, filter } from 'rxjs/operators';
import { from } from 'rxjs';
import { getLocation } from 'connected-react-router';
import { asyncActionWithCallback, WrapAction } from 'global-lib/reduxObservableUtils';
import { AppState } from '~/store';
import { authServices } from '~/application/services/auth';
import { authenticationsModule } from '~/store/auths/authentications';
import { autoLoginModule } from '~/store/ui/autoLogin';
import { autoLoginEvents } from '~/presentation/events/backgrounds';
import { errorActions } from '~/root/errorActions';
import { isPrivatePath } from '~/presentation/helpers';

const ac = actionCreatorFactory('[listener/ui/autoLogin]');
const _actions = {
  tryAutoLoginError: ac<{ pathname: string; error: any }>('tryAutoLoginError'),
};

const _tryAutoLogin = (store: AppState) => {
  const { pathname } = getLocation(store);
  return asyncActionWithCallback({
    asyncFunc: from(authServices.loginAuto()),
    error: (error: any) => _actions.tryAutoLoginError({ pathname, error }),
    next: (res) => [
      authenticationsModule.actions.login({ ...res }),
      authenticationsModule.actions.updateAccountId({ ...res }),
    ],
    complete: autoLoginModule.actions.setTryFlag(),
  });
};

const tryAutoLogin: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(autoLoginEvents.tryAutoLogin),
    //
    map(() => _tryAutoLogin(store.value)),
  );

const tryAutoLoginError: Epic<AnyAction, WrapAction<typeof errorActions.throwError>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.tryAutoLoginError),
    filter(({ payload }) => isPrivatePath(payload.pathname)),
    // privatePathのときのみ、エラーを表示する
    map(({ payload }) => errorActions.throwError(payload.error)),
  );

export const autoLoginEpics = combineEpics(tryAutoLogin, tryAutoLoginError);

import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, mergeMap } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { from } from 'rxjs';
import { push } from 'connected-react-router';
import { AppState } from '~/store';
import { initialEmailRegisterEvent } from '~/presentation/events';
import { applicationEditsModule } from '~/store/applications/edits';
import { initialEmailRegisterServices } from '~/application/services/initialEmailRegister';
import { errorActions } from '~/root/errorActions';

// _actions
type TRegistInitialEmailDone = ReturnTypeExcludePromise<typeof initialEmailRegisterServices.registInitialEmail>;
const ac = actionCreatorFactory('[listener/pages/initialEmailRegister]');
const _actions = {
  /** データ取得後にentitiesとapplication/editsに反映する */
  registInitialEmailNext: ac<TRegistInitialEmailDone>('_registInitialEmailNext'),
};

// epics
const registInitialEmail: Epic<
  AnyAction,
  WrapAction<typeof asyncActionWithCallback> | WrapAction<typeof _actions.registInitialEmailNext>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(initialEmailRegisterEvent.registInitialEmail),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(initialEmailRegisterServices.registInitialEmail(payload)),
        next: (v: ActionParameter<typeof _actions.registInitialEmailNext>) => _actions.registInitialEmailNext(v),
        error: (error: any) => errorActions.throwError(error),
        complete: push('/signup/mail/complete'),
      }),
    ),
  );

const registInitialEmailDone: Epic<
  AnyAction,
  WrapAction<typeof applicationEditsModule.actions.updateInitailRegisterEmail>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.registInitialEmailNext),
    mergeMap(({ payload }) => [
      applicationEditsModule.actions.updateInitailRegisterEmail({ data: payload.body, meta: {} }),
    ]),
  );

export const initialEmailRegisterEpics = combineEpics(registInitialEmail, registInitialEmailDone);

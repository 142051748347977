import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

type TState = typeof initialState;

const _module = createSlice({
  name: '[store/ui/loading]',
  initialState,
  reducers: {
    on(state: TState, action: PayloadAction<void>) {
      return { ...state, isLoading: true };
    },
    off(state: TState, action: PayloadAction<void>) {
      return { ...state, isLoading: false };
    },
  },
});

export const loadingModule = _module;

import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory('[events/pages/resetpassword]');
export type TPassword = {
  password: string;
  token: string;
};
export type TVerifyToken = {
  token: string;
};
const actions = {
  resetPassword: ac<TPassword>('resetpassword'),
  verifyToken: ac<TVerifyToken>('verifyToken'),
};

export const resetPasswordEvent = actions;

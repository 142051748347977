import actionCreatorFactory from 'typescript-fsa';
import { EcouponIssued } from 'project-domain/models/ecouponIssued';

// actions
const ac = actionCreatorFactory('[events/pages/ecouponCreate]');
const actions = {
  registEcoupon: ac<EcouponIssued>('registEcoupon'),
  getCouponList: ac<{ id: string }>('getCouponList'),
  deleteCoupon: ac<{ id: string }>('deleteCoupon'),
  loadEcouponCreateAggregation: ac('loadEcouponCreateAggregation'),
};
export const ecouponCreateEvent = actions;

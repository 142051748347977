import { homeRepositories } from '~/repositories/home';

const getNewsList = async () => {
  const res = await homeRepositories.getList({
    mode: 'all',
  });
  if (res instanceof Error) {
    throw res;
  }
  return res;
};

export const homeServices = {
  getNewsList,
};

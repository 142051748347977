import { AxiosRequestCustomConfig, CreateUpdateRequestParameter, CreateUpdateResponse } from 'global-lib/axios';
import { User, UserModes } from 'project-domain/models/user';
import { apiClient } from '~/di';

const url = 'user/register';
const userCreate: AxiosRequestCustomConfig<
  CreateUpdateRequestParameter<User, UserModes>,
  CreateUpdateResponse<User, UserModes>
> = {
  url,
  useMock: false,
};

export const profileCreateRepositories = {
  save: apiClient.getSaveFn(userCreate),
};

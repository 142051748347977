import { AxiosRequestCustomConfig, OtherRequestParameter, OtherResponse } from 'global-lib/axios';
import { apiClient } from '~/di';

const login: AxiosRequestCustomConfig<OtherRequestParameter, OtherResponse> = {
  url: 'login',
  useMock: false,
};

const logout: AxiosRequestCustomConfig<OtherRequestParameter, OtherResponse> = {
  url: 'logout',
  useMock: false,
};

const verifyToken: AxiosRequestCustomConfig<OtherRequestParameter, OtherResponse> = {
  url: 'verifyToken',
  useMock: false,
};

const loginAuto: AxiosRequestCustomConfig<OtherRequestParameter, OtherResponse> = {
  url: 'autoLogin',
  useMock: false,
};

export const authRepositories = {
  login: apiClient.postOtherFn(login),
  logout: apiClient.postOtherFn(logout),
  verifyToken: apiClient.postOtherFn(verifyToken),
  loginAuto: apiClient.postOtherFn(loginAuto),
};

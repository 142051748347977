import {
  AxiosRequestCustomConfig,
  CreateUpdateRequestParameter,
  CreateUpdateResponse,
  ReadDetailRequestParameter,
  ReadDetailResponse,
} from 'global-lib/axios';
import { Account, AccountModes } from 'project-domain/models/account';
import { apiClient } from '~/di';

const url = 'account';
const accountCreate: AxiosRequestCustomConfig<
  CreateUpdateRequestParameter<Account, AccountModes>,
  CreateUpdateResponse<Account, AccountModes>
> = {
  url: `${url}/register`,
  useMock: false,
};

const getDetail: AxiosRequestCustomConfig<
  ReadDetailRequestParameter<Account, AccountModes>,
  ReadDetailResponse<Account, AccountModes>
> = {
  url: `${url}/:id`,
  useMock: false,
};

export const accountCreateRepositories = {
  save: apiClient.getSaveFn(accountCreate),
  getDetail: apiClient.getDetailFn(getDetail),
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { autoLoginSelector } from '~/application/selector/ui';
import { autoLoginEvents } from '~/presentation/events';

const AutoLogin: React.FC<{}> = ({ children = null }) => {
  const isTried = useSelector(autoLoginSelector.isTried);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(autoLoginEvents.tryAutoLogin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return !isTried ? null : <>{children}</>;
};

export default AutoLogin;

import actionCreatorFactory from 'typescript-fsa';

export type TWithdrawUserParams = {
  withdrawContent: string;
};

const ac = actionCreatorFactory('[events/pages/withdraw]');

const actions = {
  withdrawUser: ac<TWithdrawUserParams>('withdrawUser'),
};

export const withdrawUserEvent = actions;

import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, tap, mergeMap } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { from } from 'rxjs';
import { replace, push } from 'connected-react-router';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { AppState } from '~/store';
import { errorActions } from '~/root/errorActions';

import { resetPasswordEvent } from '~/presentation/events';
import { userService } from '~/application/services/user';
import { authServices } from '~/application/services/auth';
import { Logger } from '~/di';

type TVerifyTokenDone = ReturnTypeExcludePromise<typeof authServices.verifyToken>;
const ac = actionCreatorFactory('[listener/pages/resetpassword]');
const _actions = {
  verifyTokenNext: ac<TVerifyTokenDone>('_verifyTokenNext'),
};
const resetPassword: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(resetPasswordEvent.resetPassword),
    map((payolad) =>
      asyncActionWithCallback({
        asyncFunc: from(userService.resetPassword(payolad.payload)),
        error: (error: any) => [errorActions.throwError(error)],
        next: replace('/resetpassword/register/complete'),
      }),
    ),
  );

const verifyToken: Epic<
  AnyAction,
  WrapAction<typeof asyncActionWithCallback> | WrapAction<typeof _actions.verifyTokenNext>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(resetPasswordEvent.verifyToken),
    tap((v) => Logger.log(v.payload)),
    map((payolad) => {
      return asyncActionWithCallback({
        asyncFunc: from(authServices.verifyToken({ token: payolad.payload.token })),
        next: (v: ActionParameter<typeof _actions.verifyTokenNext>) => _actions.verifyTokenNext(v),
      });
    }),
  );

const verifyTokenDone: Epic<AnyAction, AnyAction, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.verifyTokenNext),
    tap((v) => Logger.log(v)),
    mergeMap(({ payload }) => {
      if (!payload.result) {
        return [push('/resetpasswordentry/register')];
      }
      return [];
    }),
  );
export const resetPasswordEpics = combineEpics(resetPassword, verifyToken, verifyTokenDone);

import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, mergeMap } from 'rxjs/operators';
import { replace, CallHistoryMethodAction } from 'connected-react-router';
import { isContractExpiresWithinOneMonth } from 'project-domain/models/user/userStatus';
import { asyncActionWithCallback, WrapAction } from 'global-lib/reduxObservableUtils';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { from } from 'rxjs';
import { AppState } from '~/store';
import { loginPageEvent } from '~/presentation/events';

import { TCredential } from '~/presentation/types';
import { authenticationsModule } from '~/store/auths/authentications';
import { authServices } from '~/application/services/auth';
import { loadingModule } from '~/store/ui/loading';
import { errorActions } from '~/root/errorActions';
import { modalModule } from '~/store/ui/modal';

type TLoginDone = ReturnTypeExcludePromise<typeof authServices.login>;
const ac = actionCreatorFactory('[listener/pages/login]');
const _actions = {
  loginDone: ac<TLoginDone>('_loginDone'),
};

const _login = (payload: TCredential) => {
  return asyncActionWithCallback({
    asyncFunc: from(authServices.login(payload)),
    previous: loadingModule.actions.on(),
    next: (res) => _actions.loginDone(res),
    error: (err: any) => errorActions.throwError(err),
    complete: [loadingModule.actions.off()],
  });
};

const login: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(loginPageEvent.login),
    map(({ payload }) => _login(payload)),
  );

const loginDone: Epic<
  AnyAction,
  | WrapAction<typeof authenticationsModule.actions.login | typeof modalModule.actions.openModal>
  | CallHistoryMethodAction,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.loginDone),
    mergeMap(({ payload }) => [
      authenticationsModule.actions.login(payload),
      replace('/mypage/'),
      payload.accountId.length !== 0 && !isContractExpiresWithinOneMonth(payload.isActivate)
        ? modalModule.actions.openModal({ type: 'LOGIN', isOpen: false })
        : modalModule.actions.openModal({
            type: 'LOGIN',
            doneBtnText: '設定する',
            cancelBtnText: '後でする',
            isOpen: true,
            transitionPageURL: '/mypage/setting/account/create',
          }),
    ]),
  );

export const loginEpics = combineEpics(login, loginDone);

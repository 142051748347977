import actionCreatorFactory from 'typescript-fsa';

type TLoadEcoupon = {
  id: string;
};

const ac = actionCreatorFactory('[events/pages/ecoupons]');
const actions = {
  loadEcoupon: ac<TLoadEcoupon>('loadEcoupon'),
};
export const ecoupons = actions;

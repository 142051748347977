import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pick } from 'global-lib/ramda';
import { LoggedInResponse, LoggedInUser } from '~/presentation/types';

type TAuthState = {
  isAuthenticated: boolean;
  user: LoggedInUser;
  token: string;
};

const initialUser: LoggedInUser = {
  id: '',
  accountId: '',
  firstName: '',
  lastName: '',
  isActivate: 2,
};
const initialState: TAuthState = {
  isAuthenticated: false,
  token: '',
  user: { ...initialUser },
};

const _module = createSlice({
  name: '[auths/authentications]',
  initialState,
  reducers: {
    login(state: TAuthState, action: PayloadAction<LoggedInResponse>) {
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        user: {
          ...initialUser,
          ...pick(Object.keys(initialUser), action.payload),
        },
      };
    },
    logout(state: TAuthState, action: PayloadAction<void>) {
      return {
        ...state,
        ...initialState,
      };
    },
    updateAccountId(state: TAuthState, action: PayloadAction<Pick<LoggedInResponse, 'accountId'>>) {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    },
  },
});

export const authenticationsModule = _module;

import { AxiosRequestCustomConfig, OtherRequestParameter, OtherResponse } from 'global-lib/axios';
import { apiClient } from '~/di';

const url = 'user';
const sendEmailToUser: AxiosRequestCustomConfig<OtherRequestParameter, OtherResponse> = {
  url: `${url}/sendEmail`,
  useMock: false,
};

export const resetpasswordentryRepositories = {
  sendEmailToUser: apiClient.postOtherFn(sendEmailToUser),
};

import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { Epic, combineEpics } from 'redux-observable';
import { AnyAction } from '@reduxjs/toolkit';
import { map, mergeMap } from 'rxjs/operators';
import { CallHistoryMethodAction, getLocation, replace } from 'connected-react-router';
import { WrapAction } from 'global-lib/reduxObservableUtils';
import { isErrorCode } from 'global-lib/types/error';
import { ERROR_MESSAGE } from 'project-domain/lookups';
import { errorDialogModule } from '~/store/ui/errorDialog';
import { AppState } from '~/store';
import { errorActions } from '~/root/errorActions';
import { getRedirectPath } from '~/presentation/helpers';

const setError = (payload: any, state: AppState) => {
  const statusCode = payload?.statusCode;
  const code = payload?.code || 'unknown';
  const message =
    isErrorCode(code) && code !== 'unknown' ? ERROR_MESSAGE[code] : payload?.message || '不明なエラーが発生しました.';
  const showErrorDialogAction = errorDialogModule.actions.set({
    code,
    message,
  });
  if (statusCode === 403) {
    const currentPath = getLocation(state).pathname;
    const redirectPath = getRedirectPath(currentPath);
    return [replace(redirectPath), showErrorDialogAction];
  }
  return [showErrorDialogAction];
};

const catchError: Epic<
  AnyAction,
  WrapAction<typeof errorDialogModule.actions.set> | CallHistoryMethodAction,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(errorActions.throwError),
    map(({ payload }) => payload),
    mergeMap((payload) => setError(payload, store.value)),
  );

const clearError: Epic<AnyAction, WrapAction<typeof errorDialogModule.actions.clear>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(errorActions.clearError),
    map(({ payload }) => errorDialogModule.actions.clear()),
  );

export const errorDialogEpics = combineEpics(catchError, clearError);

import { AxiosRequestCustomConfig, OtherRequestParameter, OtherResponse } from 'global-lib/axios';
import { apiClient } from '~/di';

const url = 'initial-email';
const initialEmailConfirm: AxiosRequestCustomConfig<OtherRequestParameter, OtherResponse> = {
  url: `${url}/confirm`,
  useMock: false,
};

export const initialEmailConfirmRepositories = {
  confirm: apiClient.postOtherFn(initialEmailConfirm),
};

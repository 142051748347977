import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  hasError: false,
  code: '',
  message: '',
};
type TState = typeof initialState;

const _module = createSlice({
  name: '[store/ui/errorDialog]',
  initialState,
  reducers: {
    set(state: TState, action: PayloadAction<Omit<TState, 'hasError'>>) {
      return { ...state, ...action.payload, hasError: true };
    },
    clear(state: TState, action: PayloadAction<void>) {
      return { ...state, ...initialState };
    },
  },
});

export const errorDialogModule = _module;

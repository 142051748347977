import React, { useEffect, useContext, useState } from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import { SnackbarCloseReason } from '@material-ui/core';
import { NotifierContext } from './NotifierProvider';

import Button from '../../atoms/Button/Button';

const Notifier: React.FC<WithSnackbarProps> = ({ closeSnackbar, enqueueSnackbar }) => {
  const [displayedIds, setDisplayedIds] = useState<string[]>([]);
  const { dismiss, notifications } = useContext(NotifierContext);

  useEffect(() => {
    const addDisplayed = (v: string) => setDisplayedIds([...displayedIds, v]);
    notifications.forEach(({ id, message, options = {}, hasDismissed = false }) => {
      // dismiss
      const onDismiss = (v: string) => () => dismiss({ id: v });
      if (hasDismissed) {
        closeSnackbar(id);
        return;
      }

      // Do nothing if snackbar is already displayed
      if (displayedIds.includes(id)) {
        return;
      }

      // Keep track of snackbars that we've displayed
      addDisplayed(id);

      // Display snackbar using notistack
      enqueueSnackbar(message, {
        ...options,
        key: id,
        variant: options?.variant || 'info',
        autoHideDuration: options?.autoHideDuration || 2000,
        onClose: (event: React.SyntheticEvent<any>, reason: SnackbarCloseReason) => {
          if (options.onClose) {
            options.onClose(event, reason);
          }
        },
        onExit: (handler: any) => {
          closeSnackbar(id);
        },
        action: (v: string) => <Button onClick={onDismiss(id)} text="OK" />,
      });
    });
  }, [notifications, closeSnackbar, dismiss, enqueueSnackbar, displayedIds, setDisplayedIds]);

  return null;
};

export default withSnackbar(Notifier);

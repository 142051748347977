import { combineReducers } from 'redux';
import { couponModule } from './coupon';
import { initialUserEmailModule } from './initialUserEmail';
import { userDetailModule } from './userDetail';
import { ecouponCreateAggregationModule } from './ecouponCreateAggregation';
import { newsDetailModule } from './newsDetail';

const _entities = {
  ecoupons: couponModule.reducer,
  initialUserEmail: initialUserEmailModule.reducer,
  userDetail: userDetailModule.reducer,
  ecouponCreateAggregation: ecouponCreateAggregationModule.reducer,
  newsDetail: newsDetailModule.reducer,
};
export const entitiesReducers = combineReducers(_entities);

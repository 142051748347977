import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TModal = {
  type?: 'WITHDRAW' | 'LOGIN';
  messageText?: string;
  doneBtnText?: string;
  cancelBtnText?: string;
  isOpen: boolean;
  transitionPageURL?: string;
};

const initialState: TModal = {
  messageText: '',
  doneBtnText: '',
  cancelBtnText: '',
  isOpen: false,
  transitionPageURL: '',
};

const _module = createSlice({
  name: '[modal]',
  initialState,
  reducers: {
    openModal(state: TModal, action: PayloadAction<TModal>) {
      // const { messageText, buttonTextDone, buttonTextCancel, isOpen } = action.payload;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const modalModule = _module;

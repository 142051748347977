import React from 'react';
import { returnVoidDummyFn } from '~/utils/dummy';
import FormBtnSet from '../../molecules/FormBtnSet/FormBtnSet';

type TProps = {
  hasOpened?: boolean;
  title?: string;
  code?: string;
  message?: string;
  closeBtnLabel?: string;
  close?: () => void;
};

const getTitle = (code: string): string => 'メッセージ';

const DialogError: React.FC<TProps> = ({
  hasOpened = false,
  code = 'unknown',
  title,
  message = '',
  closeBtnLabel = '閉じる',
  close = returnVoidDummyFn,
}) => {
  if (!hasOpened) {
    return null;
  }
  return (
    <div className="dialogWrap">
      <div className="dialogContainer">
        <div className="close">
          <button type="button" onClick={close}>
            <img src="/images/icn-close.svg" alt="閉じる" />
          </button>
        </div>
        <div className="dialogTextSet">
          <div className="title">{`${title || getTitle(code)}`}</div>
          <div className="text" style={{ whiteSpace: 'pre-wrap' }}>
            {message}
          </div>
        </div>
        <div className="dialogBtnWrap">
          <FormBtnSet
            setStyles={['noBorder', 'dialogBtnSet', 'alignCenter']}
            textRight={closeBtnLabel}
            stylesRight={[]}
            onClickRight={close}
          />
        </div>
      </div>
    </div>
  );
};
export default DialogError;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EcouponIssued } from 'project-domain/models/ecouponIssued';
import { uniqBy } from 'global-lib/ramda';
import { TStoreEntity } from '~/store/types';
import { addEntityMeta } from '~/store/helpers';

type TState = TStoreEntity<EcouponIssued>[];

const initialState: TState = [];

const _module = createSlice({
  name: '[store/entities/coupon]',
  initialState,
  reducers: {
    read(state: TState, action: PayloadAction<EcouponIssued[]>) {
      const newData = action.payload.map(addEntityMeta);
      const mergedArray = [...newData, ...state];
      return uniqBy((v: TStoreEntity<EcouponIssued>) => v.data.id, mergedArray);
    },
  },
});

export const couponModule = _module;

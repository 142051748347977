export const EPrivateRootPath = {
  top: '/',
} as const;
export type TPrivateRootPath = typeof EPrivateRootPath[keyof typeof EPrivateRootPath];

export const EPath = {
  top: '/',
  summary: '/signup',
  profileCreate: '/signup/profile/create',
  terms: '/signup/terms',
  profileConfirm: '/signup/profile/confirm',
  profileComplete: '/signup/complete',
  login: '/login',
  home: '/mypage/',
  contact: '/mypage/contact',
  resetPasswordComplete: '/resetpassword/register/complete',
} as const;
export type TPage = keyof typeof EPath;
export type TPath = typeof EPath[TPage];

import { newEcouponCreateAggregation4PartialEntity } from 'project-domain/models/ecouponCreateAggregation';
import { ecouponCreateAggregationRepositories } from '~/repositories/ecouponCreateAggregation';

const getDetail = async (id: string) => {
  const res = await ecouponCreateAggregationRepositories.getDetail({
    id,
    mode: 'all',
  });
  if (res instanceof Error) {
    throw res;
  }
  const data = newEcouponCreateAggregation4PartialEntity(id, res.data);
  return { data, id };
};

export const ecouponCreateAggregationServices = {
  getDetail,
};

import { Inquiry } from 'project-domain/models/inquiry';
import { contactRepositories } from '~/repositories/contact';

const createContact = async (contact: Inquiry) => {
  const res = await contactRepositories.save({
    id: contact.userId,
    mode: 'all',
    body: contact,
  });

  if (res instanceof Error) {
    throw res;
  }

  return res;
};

export const contactServices = {
  createContact,
};

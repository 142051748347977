import { AxiosRequestCustomConfig, CreateUpdateRequestParameter, CreateUpdateResponse } from 'global-lib/axios';
import { UserInitialEmail, UserInitialEmailModes } from 'project-domain/models/userInitialEmail';
import { apiClient } from '~/di';

const url = 'initial-email';
const initialEmailRegister: AxiosRequestCustomConfig<
  CreateUpdateRequestParameter<UserInitialEmail, UserInitialEmailModes>,
  CreateUpdateResponse<UserInitialEmail, UserInitialEmailModes>
> = {
  url: `${url}/register`,
  useMock: false,
};

export const initialEmailRegisterRepositories = {
  save: apiClient.getSaveFn(initialEmailRegister),
};

import { combineReducers } from 'redux';
import { modalModule } from './modal';
import { loadingModule } from './loading';
import { snackbarModule } from './snackbar';
import { errorDialogModule } from './errorDialog';
import { autoLoginModule } from './autoLogin';

export const uiReducers = combineReducers({
  modal: modalModule.reducer,
  loading: loadingModule.reducer,
  snackbar: snackbarModule.reducer,
  errorDialog: errorDialogModule.reducer,
  autoLogin: autoLoginModule.reducer,
});

import nanoid from 'nanoid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TNotification = {
  id: string;
  message: string;
  hasDismissed?: boolean;
};
type TState = {
  notifications: TNotification[];
};
const initialState: TState = {
  notifications: [],
};
const _module = createSlice({
  name: '[store/ui/snackbar]',
  initialState,
  reducers: {
    enqueue(state: TState, action: PayloadAction<Omit<TNotification, 'id'>>) {
      return {
        ...state,
        notifications: [...state.notifications, { ...action.payload, id: nanoid() }],
      };
    },
    dismiss(state: TState, action: PayloadAction<{ id: string }>) {
      return {
        ...state,
        notifications: [
          ...state.notifications.map((v) => (v.id !== action.payload.id ? v : { ...v, hasDismissed: true })),
        ],
      };
    },
  },
});
export const snackbarModule = _module;

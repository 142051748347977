import { AxiosRequestCustomConfig, ReadDetailRequestParameter, ReadDetailResponse } from 'global-lib/axios';
import {
  EcouponCreateAggregation,
  EcouponCreateAggregationModes,
} from 'project-domain/models/ecouponCreateAggregation';
import { apiClient } from '~/di';

const url = 'ecouponCreateAggregation';
const getDetail: AxiosRequestCustomConfig<
  ReadDetailRequestParameter<EcouponCreateAggregation, EcouponCreateAggregationModes>,
  ReadDetailResponse<EcouponCreateAggregation, EcouponCreateAggregationModes>
> = {
  url: `${url}/:id`,
  useMock: false,
};

export const ecouponCreateAggregationRepositories = {
  getDetail: apiClient.getDetailFn(getDetail),
};

import { map, pipe, addIndex, when, append, take } from 'global-lib/ramda';
import { toDateStr, EDateFormat, isValidDateStr, parseISO } from 'global-lib/dateFns';
import { TName } from 'global-lib/types';
import { getValueOrElse } from 'global-lib/utils';
import { TTagItem, TSetLocalStorage, TGetLocalStorage, TConfig } from '~/presentation/types';

export const _toTagItem = (v: string): TTagItem => ({ name: v });
export const toTagItems = map(_toTagItem);

export const parseDate = (date: Date | string): Date => (typeof date === 'string' ? parseISO(date) : date);
export const toTwoDigit = (n: string) => (n.length === 1 ? `0${n}` : n);
const mapIndexed = addIndex(map);
const _toTagItem2 = (v: any, idx: number): TTagItem => ({ index: idx + 1, name: v });
export const toTagWithIndexItems = (list: string[]) => mapIndexed(_toTagItem2, list);

export const dateStr2dateStrJp = toDateStr(EDateFormat['yyyy-MM-dd'], EDateFormat.yyyy年M月d日);
export const toDateRange = (v?: { start: string; end: string }) => {
  return !v
    ? { start: '', end: '' }
    : {
        start: isValidDateStr(v.start, EDateFormat['yyyy-MM-dd']) ? dateStr2dateStrJp(v.start) : '',
        end: isValidDateStr(v.end, EDateFormat['yyyy-MM-dd']) ? dateStr2dateStrJp(v.end) : '',
      };
};
export const yearMonthStr2dateStrJp = toDateStr(EDateFormat['yyyy-MM-dd'], EDateFormat.yyyy年MM月dd日);
export const toYearMonthRange = (v?: { start: string; end: string }) => {
  return !v
    ? { start: '', end: '' }
    : {
        start: isValidDateStr(v.start, EDateFormat['yyyy-MM-dd']) ? yearMonthStr2dateStrJp(v.start) : '',
        end: isValidDateStr(v.end, EDateFormat['yyyy-MM-dd']) ? yearMonthStr2dateStrJp(v.end) : '',
      };
};
export const nameObj2str = (name?: TName<string>): string =>
  !name ? '' : [getValueOrElse('', name.lastName), getValueOrElse('', name.firstName)].join('') || '';

export const truncateStr = (limit: number) =>
  when(
    (st: string) => st.length > limit,
    pipe(
      (st: string) => st.split(''),
      take(limit),
      append('...'),
      (arr) => arr.join(''),
    ),
  );

export const setLocalStorage: TSetLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage: TGetLocalStorage = (key) => {
  return localStorage.getItem(key) as TConfig[typeof key];
};

export const goToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

export const copyToClipboard = (text: string) => {
  const input = document.createElement('input');
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand('Copy');
  input.remove();
};

import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, tap, mergeMap } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { from } from 'rxjs';
import { push } from 'connected-react-router';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { AppState } from '~/store';
import { initialEmailConfirmEvent } from '~/presentation/events';
import { initialEmailConfirmServices } from '~/application/services/initialEmailConfirm';
import { applicationEditsModule } from '~/store/applications/edits';
import { Logger } from '~/di';
import { errorActions } from '~/root/errorActions';

type TInitialEmailConfirmDone = ReturnTypeExcludePromise<typeof initialEmailConfirmServices.initialEmailConfirm>;
const ac = actionCreatorFactory('[listener/pages/initialEmailConfirm]');
const _actions = {
  initialEmailConfirmNext: ac<TInitialEmailConfirmDone>('_initialEmailConfirmNext'),
};
// epics
const initialEmailConfirm: Epic<
  AnyAction,
  WrapAction<typeof asyncActionWithCallback> | WrapAction<typeof _actions.initialEmailConfirmNext>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(initialEmailConfirmEvent.initialEmailConfirm),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(initialEmailConfirmServices.initialEmailConfirm(payload)),
        error: (error: any) => [push('/'), errorActions.throwError(error)],
        next: (v: ActionParameter<typeof _actions.initialEmailConfirmNext>) => _actions.initialEmailConfirmNext(v),
      }),
    ),
  );
const initialEmailConfirmDone: Epic<
  AnyAction,
  WrapAction<typeof applicationEditsModule.actions.updateInitailRegisterEmail>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.initialEmailConfirmNext),
    tap((v) => Logger.log(v)),
    mergeMap(({ payload }) => [
      applicationEditsModule.actions.updateInitailRegisterEmail({ data: payload.body, meta: {} }),
    ]),
  );
export const initialEmailConfirmEpics = combineEpics(initialEmailConfirm, initialEmailConfirmDone);

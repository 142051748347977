import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartialInquiry } from 'project-domain/models/inquiry';
import { PartialCoupon } from 'project-domain/models/coupon';
import { PartialUser, PartialDestinationEmail } from 'project-domain/models/user';
import { PartialAccount } from 'project-domain/models/account';
import { PartialUserInitialEmail } from 'project-domain/models/userInitialEmail';
import { TStoreEdit } from '~/store/types';

type TPayload<T> = TStoreEdit<T>;
type TState = {
  contactRegist: TStoreEdit<PartialInquiry>;
  couponRegist: TStoreEdit<PartialCoupon>;
  userRegist: TStoreEdit<PartialUser>;
  accountRegist: TStoreEdit<PartialAccount>;
  initialUserEmail: TStoreEdit<PartialUserInitialEmail>;
  destinationEmail: TStoreEdit<PartialDestinationEmail>;
};
const getInitialFeatureState = <T>() => ({ data: {}, meta: {} } as TStoreEdit<T>);
const initialState = {
  contactRegist: { ...getInitialFeatureState<PartialInquiry>() },
  couponRegist: { ...getInitialFeatureState<PartialCoupon>() },
  userRegist: { ...getInitialFeatureState<PartialUser>() },
  accountRegist: {
    ...getInitialFeatureState<PartialAccount>(),
  },
  initialUserEmail: { ...getInitialFeatureState<PartialUserInitialEmail>() },
  destinationEmail: {
    ...getInitialFeatureState<PartialDestinationEmail>(),
  },
};

const _module = createSlice({
  name: '[application/edits]',
  initialState,
  reducers: {
    updateRegistContactData(state: TState, action: PayloadAction<TPayload<PartialInquiry>>) {
      const { data, meta } = action.payload;
      return {
        ...state,
        contactRegist: {
          data: { ...state.contactRegist.data, ...data },
          meta,
        },
      };
    },
    updateRegistCouponData(state: TState, action: PayloadAction<TPayload<PartialCoupon>>) {
      const { data, meta } = action.payload;
      return {
        ...state,
        couponRegist: {
          data: { ...state.couponRegist.data, ...data },
          meta,
        },
      };
    },
    updateRegistAccountData(state: TState, action: PayloadAction<TPayload<PartialAccount>>) {
      const { data, meta } = action.payload;
      return {
        ...state,
        accountRegist: {
          data: { ...state.accountRegist.data, ...data },
          meta,
        },
      };
    },

    updateProfileCreateData(state: TState, action: PayloadAction<TPayload<PartialUser>>) {
      const { data, meta } = action.payload;
      return {
        ...state,
        userRegist: {
          data: { ...state.userRegist.data, ...data },
          meta,
        },
      };
    },
    updateInitailRegisterEmail(state: TState, action: PayloadAction<TPayload<PartialUserInitialEmail>>) {
      const { data, meta } = action.payload;
      return {
        ...state,
        initialUserEmail: {
          data: { ...state.initialUserEmail.data, ...data },
          meta,
        },
      };
    },
    sendEmail2User(state: TState, action: PayloadAction<TPayload<PartialDestinationEmail>>) {
      const { data, meta } = action.payload;
      return {
        ...state,
        destinationEmail: {
          ...state.destinationEmail.data,
          data,
          meta,
        },
      };
    },
  },
});

export const applicationEditsModule = _module;

import { AxiosRequestConfigWithFns } from 'global-lib/axios';
import { config } from '../config';

const CREDENTIAL_KEY = 'withCredentials';

export const ApiServerConfig: AxiosRequestConfigWithFns = {
  // MEMO: 'npm run start'の場合、
  // package.jsonでproxyに 'http://localhost:4000' を指定しているので、'/api/v1'のみでOK
  baseURL: process.env.REACT_APP_SERVER_URL || '/api/v1',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': config.isDev ? '*' : process.env.REACT_APP_SERVER_URL,
    [CREDENTIAL_KEY]: true,
  },
};

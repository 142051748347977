import { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback } from 'global-lib/reduxObservableUtils';
import { from } from 'rxjs';
import { AppState } from '~/store';
import { ecoupons } from '~/presentation/events';
import { couponServices } from '~/application/services/coupon';
import { couponModule } from '~/store/entities/coupon';

const loadEcoupon: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(ecoupons.loadEcoupon),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(couponServices.getDetail(payload.id)),
        next: ({ data }) => [couponModule.actions.read([data])],
      }),
    ),
  );

export const ecouponEpics = combineEpics(loadEcoupon);

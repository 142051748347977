import { ApiError } from 'global-lib/types/error';
import { TInitialEmailConfirm } from '~/presentation/types';
import { initialEmailConfirmRepositories } from '~/repositories/initialEmailConfirm';

const initialEmailConfirm = async (param: TInitialEmailConfirm) => {
  const res = await initialEmailConfirmRepositories.confirm({
    body: param,
  });

  if (res instanceof Error) {
    throw new ApiError(403, { message: '既に存在するメールアドレスです。' });
  }
  if (!res.result) {
    throw new ApiError(403, { message: '既に存在するメールアドレスです。' });
  }

  return res;
};

export const initialEmailConfirmServices = {
  initialEmailConfirm,
};

import React, { useState, createContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Logger } from '~/di';
import { loadingSelector } from '../../../../application/selector/ui';

type TProps = {
  on: () => void;
  off: () => void;
  switch: (v: boolean) => void;
};

type TState = {
  isLoading: boolean;
};

const INITIAL_PROPS_STATE: TProps & TState = {
  on: () => {
    Logger.log('Loading on');
  },
  off: () => {
    Logger.log('Loading off');
  },
  switch: (v: boolean) => {
    Logger.log(`Loading ${v}`);
  },
  isLoading: false,
};

const LoadingContext = createContext(INITIAL_PROPS_STATE);
const LoadingProvider: React.FC<{}> = ({ children = null }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isLoadingState = useSelector(loadingSelector.isLoading);

  const on = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const off = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const switchState = useCallback(
    (v: boolean) => {
      setIsLoading(v);
    },
    [setIsLoading],
  );

  return (
    <LoadingContext.Provider value={{ on, off, switch: switchState, isLoading: isLoading || isLoadingState }}>
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };

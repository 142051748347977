import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'global-lib/ramda';
import { UserInitialEmail } from 'project-domain/models/userInitialEmail';
import { TStoreEntity } from '~/store/types';
import { addEntityMeta } from '~/store/helpers';

type TState = TStoreEntity<UserInitialEmail>[];

const initialState: TState = [];

const _module = createSlice({
  name: '[store/entities/initialUserEmail]',
  initialState,
  reducers: {
    read(state: TState, action: PayloadAction<UserInitialEmail[]>) {
      const newData = action.payload.map(addEntityMeta);
      const mergedArray = [...newData, ...state];
      return uniqBy((v: TStoreEntity<UserInitialEmail>) => v.data.id, mergedArray);
    },
  },
});

export const initialUserEmailModule = _module;

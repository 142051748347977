import { combineEpics } from 'redux-observable';
import {
  contactEpics,
  profileCreateEpics,
  loginEpics,
  ecouponEpics,
  userEpics,
  accountCreateEpics,
  initialEmailRegisterEpics,
  initialEmailConfirmEpics,
  userDetailEpics,
  sendEmailToUserEpics,
  resetPasswordEpics,
  ecouponCreateEpics,
  homeEpics,
  logoutEpics,
} from './pages';
import { errorDialogEpics, autoLoginEpics } from './ui';

export const EventListeners = combineEpics(
  profileCreateEpics,
  contactEpics,
  loginEpics,
  ecouponEpics,
  userEpics,
  initialEmailRegisterEpics,
  userDetailEpics,
  errorDialogEpics,
  accountCreateEpics,
  initialEmailConfirmEpics,
  sendEmailToUserEpics,
  resetPasswordEpics,
  homeEpics,
  autoLoginEpics,
  ecouponCreateEpics,
  logoutEpics,
);

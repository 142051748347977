import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { News } from 'project-domain/models/news';
import { TStoreEdit } from '~/store/types';

type TState = TStoreEdit<News[]>;

const initialState: TState = {
  data: [],
  meta: {},
};

const _module = createSlice({
  name: '[store/entities/newsDetail]',
  initialState,
  reducers: {
    read(state: TState, action: PayloadAction<News[]>) {
      const news = {
        data: action.payload.sort((a, b) => {
          return a.date < b.date ? 1 : -1;
        }),
      };
      return { ...state, ...news };
    },
  },
});

export const newsDetailModule = _module;

import { AxiosRequestCustomConfig, ReadListRequestParameter, ReadListResponse } from 'global-lib/axios';
import { NewsModes, News } from 'project-domain/models/news';
import { apiClient } from '~/di';

const url = 'info';
const getList: AxiosRequestCustomConfig<
  ReadListRequestParameter<News, NewsModes>,
  ReadListResponse<News, NewsModes>
> = {
  url,
  useMock: false,
};

export const homeRepositories = {
  getList: apiClient.getListFn(getList),
};

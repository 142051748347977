import { EcouponIssued, newEcouponIssued } from 'project-domain/models/ecouponIssued';
import { couponRepositories } from '~/repositories/coupon';

const createCoupon = async (ecouponIssued: EcouponIssued) => {
  const res = await couponRepositories.save({
    id: ecouponIssued.userId,
    new: true,
    mode: 'all',
    body: ecouponIssued,
  });

  if (res instanceof Error) {
    throw res;
  }

  return res;
};

const getDetail = async (id: string) => {
  const res = await couponRepositories.getDetail({
    id,
    mode: 'all',
  });
  if (res instanceof Error) {
    throw res;
  }
  const data = newEcouponIssued(id, res.data);
  return { data, id };
};

export const couponServices = {
  createCoupon,
  getDetail,
};

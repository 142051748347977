import nanoid from 'nanoid';
import { UserInitialEmail } from 'project-domain/models/userInitialEmail';
import { initialEmailRegisterRepositories } from '~/repositories/initialEmailRegister';

const registInitialEmail = async (email: UserInitialEmail) => {
  const res = await initialEmailRegisterRepositories.save({
    id: nanoid(),
    mode: 'all',
    body: email,
  });

  if (res instanceof Error) {
    throw res;
  }

  return res;
};

export const initialEmailRegisterServices = {
  registInitialEmail,
};

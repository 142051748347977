import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, tap, mergeMap } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { from } from 'rxjs';
import { AppState } from '~/store';
import { withdrawUserEvent } from '~/presentation/events/pages/withdraw';
import { userService } from '~/application/services/user';
import { Logger } from '~/di';
import { rootActions } from '~/root/actions';
import { modalModule } from '~/store/ui/modal';
import { authSelector } from '~/application/selector/auth';

type TWithdrawDone = ReturnType<typeof rootActions.clearAllState | typeof modalModule.actions.openModal>;
const ac = actionCreatorFactory('[listener/pages/withdraw]');
const _actions = {
  withdrawNext: ac<TWithdrawDone>('_withdrawNext'),
};

// epics
const withdrawUser: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) => {
  return action$.pipe(
    ofAction(withdrawUserEvent.withdrawUser),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(userService.withdrawUser(authSelector.user(store.value).id, payload)),
        next: (v: ActionParameter<typeof _actions.withdrawNext>) => _actions.withdrawNext(v),
      }),
    ),
  );
};

const withdrawDone: Epic<
  AnyAction,
  WrapAction<typeof rootActions.clearAllState | typeof modalModule.actions.openModal>,
  AppState
> = (action$, store) => {
  return action$.pipe(
    ofAction(_actions.withdrawNext),
    tap(({ payload }) => Logger.log(payload)),
    mergeMap(({ payload }) => {
      return [
        modalModule.actions.openModal({
          type: 'WITHDRAW',
          messageText: '退会申請を受け付けました。',
          cancelBtnText: '閉じる',
          isOpen: true,
        }),
      ];
    }),
  );
};

export const userEpics = combineEpics(withdrawUser, withdrawDone);

import { ApiError } from 'global-lib/types/error';
import { resetpasswordentryRepositories } from '~/repositories/resetpasswordentry';

export type Email = {
  email: string;
};
const sendEmailToUser = async (param: Email) => {
  const res = await resetpasswordentryRepositories.sendEmailToUser({
    body: param,
  });
  if (res instanceof Error) {
    throw new ApiError(400, { code: 'E0005' });
  }
  if (!res.result) {
    throw new ApiError(403, { code: 'E0003' });
  }
  return res;
};

export const resetpasswordentryService = {
  sendEmailToUser,
};

import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, mergeMap } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { from } from 'rxjs';
import { push } from 'connected-react-router';
import { AppState } from '~/store';
import { profileCreateEvent } from '~/presentation/events';
import { profileCreateServices } from '~/application/services/profileCreate';
import { errorActions } from '~/root/errorActions';

// _actions
type TprofileCreateDone = ReturnTypeExcludePromise<typeof profileCreateServices.createUser>;
const ac = actionCreatorFactory('[listener/pages/profileConfirm]');
const _actions = {
  profileCreateNext: ac<TprofileCreateDone>('_profileCreateNext'),
};

// epics
const profileRegist: Epic<
  AnyAction,
  WrapAction<typeof asyncActionWithCallback> | WrapAction<typeof _actions.profileCreateNext>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(profileCreateEvent.registProfile),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(profileCreateServices.createUser(payload)),
        error: (error: any) => [push('/'), errorActions.throwError(error)],
        next: (v: ActionParameter<typeof _actions.profileCreateNext>) => _actions.profileCreateNext(v),
      }),
    ),
  );

const profileCreateDone: Epic<AnyAction, AnyAction, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.profileCreateNext),
    mergeMap((payload) => [push('/signup/complete')]),
  );

export const profileCreateEpics = combineEpics(profileRegist, profileCreateDone);

import React, { createContext, useCallback } from 'react';
import { SnackbarProvider } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { ActionParameter } from 'global-lib/reduxObservableUtils';
import { TNotification } from '~/presentation/types';
import { snackbarModule } from '~/store/ui/snackbar';
import { snackbarSelector } from '~/application/selector/ui';

type TEnqueueParam = ActionParameter<typeof snackbarModule.actions.enqueue>;
type TDismissParam = ActionParameter<typeof snackbarModule.actions.dismiss>;
type TProps = {
  enqueue: (v: TEnqueueParam) => void;
  dismiss: (v: TDismissParam) => void;
};
type TState = {
  notifications: TNotification[];
};

const NotifierContext = createContext({} as TProps & TState);

const NotifierProvider: React.FC<{}> = ({ children = null }) => {
  const notifications = useSelector(snackbarSelector.notifications);
  const dispatch = useDispatch();
  const enqueue = useCallback((v: TEnqueueParam) => dispatch(snackbarModule.actions.enqueue(v)), [dispatch]);
  const dismiss = useCallback((v: TDismissParam) => dispatch(snackbarModule.actions.dismiss(v)), [dispatch]);
  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        containerAnchorOriginTopRight: 'snackbarZIndex',
      }}
    >
      <NotifierContext.Provider value={{ enqueue, dismiss, notifications }}>{children}</NotifierContext.Provider>
    </SnackbarProvider>
  );
};

export { NotifierContext, NotifierProvider };

import {
  AxiosRequestCustomConfig,
  PatchRequestParameter,
  PatchResponse,
  ReadDetailRequestParameter,
  ReadDetailResponse,
  CreateUpdateRequestParameter,
  CreateUpdateResponse,
} from 'global-lib/axios';

import { User, UserModes } from 'project-domain/models/user';
import { apiClient } from '~/di';

const url = 'user';

const getDetail: AxiosRequestCustomConfig<
  ReadDetailRequestParameter<User, UserModes>,
  ReadDetailResponse<User, UserModes>
> = {
  url: `${url}/:id`,
  useMock: false,
};

const withdrawUser: AxiosRequestCustomConfig<PatchRequestParameter<User, UserModes>, PatchResponse> = {
  url: `${url}/withdraw/:id`,
  useMock: false,
};
const patchUserData: AxiosRequestCustomConfig<PatchRequestParameter<User, UserModes>, PatchResponse> = {
  url,
  useMock: false,
};

const updateUserDetail: AxiosRequestCustomConfig<
  CreateUpdateRequestParameter<User, UserModes>,
  CreateUpdateResponse<User, UserModes>
> = {
  url: `${url}/:id`,
  useMock: false,
};

export const userRepositories = {
  getDetail: apiClient.getDetailFn(getDetail),
  withdrawUser: apiClient.patchOtherFn(withdrawUser),
  update: apiClient.getSaveFn(updateUserDetail),
  patchUserData: apiClient.patchOtherFn(patchUserData),
};

import actionCreateFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, tap, mergeMap } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { from } from 'rxjs';
import { push } from 'connected-react-router';
import { PartialUser, User, newUser } from 'project-domain/models/user';
import { SNACKBAR_MESSAGE } from 'project-domain/lookups/';
import { snackbarModule } from '~/store/ui/snackbar';
import { userDetailModule } from '~/store/entities/userDetail';
import { AppState } from '~/store';
import { Logger } from '~/di';
import { errorActions } from '~/root/errorActions';
import { userDetailEvent } from '~/presentation/events';
import { userService } from '~/application/services/user';
import { authSelector } from '~/application/selector/auth';
import { loadingModule } from '~/store/ui/loading';

type TLoadUserDetailDone = ReturnTypeExcludePromise<typeof userService.getDetail>;
type TUpdateUserDetailDone = ReturnTypeExcludePromise<typeof userService.updateDetail>;
const ac = actionCreateFactory('[listener/pages/userDetail]');
const _actions = {
  loadUserDetailNext: ac<TLoadUserDetailDone>('_loadUserDetailNext'),
  updateUserDetailNext: ac<TUpdateUserDetailDone>('_updateUserDetailNext'),
};

// epics
const loadUserDetail: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(userDetailEvent.loadUserDetail),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(userService.getDetail(store.value.auths?.authentication?.user?.id)),
        previous: loadingModule.actions.on(),
        next: (v: ActionParameter<typeof _actions.loadUserDetailNext>) => _actions.loadUserDetailNext(v),
        error: (v: any) => errorActions.throwError(v),
        complete: loadingModule.actions.off(),
      }),
    ),
  );

const loadUserDetailDone: Epic<AnyAction, AnyAction, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.loadUserDetailNext),
    tap(({ payload }) => Logger.log(payload)),
    mergeMap(({ payload }) => {
      const { data } = payload;
      return [userDetailModule.actions.read([data])];
    }),
  );

const toUser = (user: PartialUser, state: AppState): User => {
  const id = authSelector.user(state).id || '';
  return newUser(id, user);
};

const updateUserDetail: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(userDetailEvent.updateUserDetail),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(userService.updateDetail(toUser(payload, store.value))),
        previous: loadingModule.actions.on(),
        error: (error: any) => [push('/mypage/setting'), errorActions.throwError(error)],
        next: (v: ActionParameter<typeof _actions.updateUserDetailNext>) => {
          return _actions.updateUserDetailNext(v);
        },
        complete: [
          push('/mypage/setting'),
          loadingModule.actions.off(),
          snackbarModule.actions.enqueue({ message: SNACKBAR_MESSAGE.updateDone }),
        ],
      }),
    ),
  );

export const userDetailEpics = combineEpics(loadUserDetail, loadUserDetailDone, updateUserDetail);

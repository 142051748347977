import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IndexedObject } from 'global-lib/types';
import { TStoreList } from '~/store/types';

type TState = IndexedObject<TStoreList>;
type TPayloadUpdate = TStoreList & { page: string };
const initialState: TState = {};

const _module = createSlice({
  name: '[applications/details]',
  initialState,
  reducers: {
    update(state: TState, action: PayloadAction<TPayloadUpdate>) {
      const { page, entityCd, ids, condition, meta } = action.payload;
      const { totalPages = 1 } = meta;
      return {
        ...state,
        [page]: {
          ...state[page],
          entityCd,
          ids,
          condition: {
            ...condition,
          },
          meta: {
            totalPages,
            updatedAt: new Date(),
          },
        },
      };
    },
  },
});

export const applicationDetailModule = _module;

import actionCreatorFactory from 'typescript-fsa';
import { PartialUser } from 'project-domain/models/user';

// actions
const ac = actionCreatorFactory('[events/pages/setting]');
const actions = {
  loadUserDetail: ac('loadUserDetail'),
  updateUserDetail: ac<PartialUser>('updateUserDetail'),
};
export const userDetailEvent = actions;

import actionCreatorFactory from 'typescript-fsa';
import { Account } from 'project-domain/models/account';

// actions
const ac = actionCreatorFactory('[events/pages/accountCreate]');
const actions = {
  registAccount: ac<Account>('registAccount'),
  createUpdateAccount: ac<Account>('createUpdateAccount'),
  readAccount: ac('readAccount'),
};
export const accountCreateEvent = actions;

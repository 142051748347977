import { newUser, User } from 'project-domain/models/user';
import { userRepositories } from '~/repositories/userRepositories/index';
import { TWithdrawUserParams } from '~/presentation/events/pages/withdraw';
import { TPassword } from '~/presentation/events/pages/resetpassword';

const getDetail = async (id: string) => {
  const res = await userRepositories.getDetail({
    id,
    mode: 'getDetail',
  });
  if (res instanceof Error) {
    throw res;
  }
  const data = newUser(id, res.data);
  return { data, id };
};

const withdrawUser = async (id: string, withdrawUserParams: TWithdrawUserParams) => {
  const res = await userRepositories.withdrawUser({
    id,
    body: withdrawUserParams,
  });
  if (res instanceof Error) {
    throw res;
  }
  return res;
};

const resetPassword = async (params: TPassword) => {
  const res = await userRepositories.patchUserData({
    id: '',
    mode: 'resetPassword',
    body: params,
  });
  if (res instanceof Error) {
    throw res;
  }
  return res;
};

const updateDetail = async (user: User) => {
  const res = await userRepositories.update({
    id: user.id,
    mode: 'updateDetail',
    body: user,
    new: false,
  });
  if (res instanceof Error) {
    throw res;
  }
  return res;
};
export const userService = {
  getDetail,
  withdrawUser,
  updateDetail,
  resetPassword,
};

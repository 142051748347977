import {
  AxiosRequestCustomConfig,
  CreateUpdateRequestParameter,
  CreateUpdateResponse,
  ReadDetailRequestParameter,
  ReadDetailResponse,
} from 'global-lib/axios';
import { EcouponIssued, EcouponIssuedModes } from 'project-domain/models/ecouponIssued';
import { apiClient } from '~/di';

const url = 'ecoupon-issued';
const couponCreate: AxiosRequestCustomConfig<
  CreateUpdateRequestParameter<EcouponIssued, EcouponIssuedModes>,
  CreateUpdateResponse<EcouponIssued, EcouponIssuedModes>
> = {
  url,
  useMock: false,
};

const getDetail: AxiosRequestCustomConfig<
  ReadDetailRequestParameter<EcouponIssued, EcouponIssuedModes>,
  ReadDetailResponse<EcouponIssued, EcouponIssuedModes>
> = {
  url: `${url}/:id`,
  useMock: false,
};

export const couponRepositories = {
  save: apiClient.getSaveFn(couponCreate),
  getDetail: apiClient.getDetailFn(getDetail),
};

import React from 'react';
import { getClassNameCreator } from '~/presentation/helpers';
import cssStyles from './Button.module.scss';

type TCssStylesKey = keyof typeof cssStyles;
type TProps = {
  text?: string;
  onClick?: (event: any) => void;
  styles?: TCssStylesKey[];
  disabled?: boolean;
  type?: 'button' | 'submit';
  hasError?: boolean;
};
const getClassName = getClassNameCreator<TCssStylesKey>(cssStyles);

const Button: React.FC<TProps> = ({
  text,
  onClick,
  styles = [],
  disabled = false,
  type = 'button',
  hasError = false,
}) => {
  const newStyles = hasError ? [...styles, 'error'] : styles;
  return (
    <button type={type} disabled={disabled} onClick={onClick} className={getClassName('btn', newStyles)}>
      <span>{text}</span>
    </button>
  );
};

export default Button;

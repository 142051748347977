import { AxiosRequestCustomConfig, CreateUpdateRequestParameter, CreateUpdateResponse } from 'global-lib/axios';
import { Inquiry, InquiryModes } from 'project-domain/models/inquiry';
import { apiClient } from '~/di';

const url = 'contact';
const contactCreate: AxiosRequestCustomConfig<
  CreateUpdateRequestParameter<Inquiry, InquiryModes>,
  CreateUpdateResponse<Inquiry, InquiryModes>
> = {
  url,
  useMock: false,
};

export const contactRepositories = {
  save: apiClient.getSaveFn(contactCreate),
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IndexedObject } from 'global-lib/types';
import { TStoreList } from '~/store/types';

type TState = IndexedObject<TStoreList>;
type TPayload = TStoreList & { page: string };
const initialState: TState = {};

const _module = createSlice({
  name: '[application/lists]',
  initialState,
  reducers: {
    update(state: TState, action: PayloadAction<TPayload>) {
      const { page, entityCd, ids, condition, meta } = action.payload;
      const { totalPages = 1, totalCount = 0 } = meta;
      return {
        ...state,
        [page]: {
          ...state[page],
          entityCd,
          ids,
          condition: {
            ...condition,
          },
          meta: {
            totalPages,
            totalCount,
            updatedAt: new Date(),
          },
        },
      };
    },
  },
});

export const applicationListsModule = _module;

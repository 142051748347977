const isProduction = process.env.NODE_ENV === 'production';
const isStaging = process.env.REACT_APP_STAGING_FLAG === '1';
const isTest = process.env.NODE_ENV === 'test';

export const config = {
  isProduction: isProduction && !isStaging,
  isStaging,
  isTest,
  isDev: !isProduction && !isStaging,
};

import actionCreatorFactory from 'typescript-fsa';
import { User } from 'project-domain/models/user';

// actions
const ac = actionCreatorFactory('[events/pages/profileCreate]');
const actions = {
  registProfile: ac<User>('registProfile'),
  registProfileConfirm: ac<User>('registProfileConfirm'),
};
export const profileCreateEvent = actions;

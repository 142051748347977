import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { from } from 'rxjs';
import { push } from 'connected-react-router';
import { AppState } from '~/store';
import { ecouponCreateEvent } from '~/presentation/events';
import { couponServices } from '~/application/services/coupon';
import { authSelector } from '~/application/selector/auth';
import { ecouponCreateAggregationServices } from '~/application/services/ecouponCreateAggregation';
import { ecouponCreateAggregationModule } from '~/store/entities/ecouponCreateAggregation';

// _actions
type TregistEcouponDone = ReturnTypeExcludePromise<typeof couponServices.createCoupon>;
const ac = actionCreatorFactory('[listener/pages/ecouponCreate]');
const _actions = {
  /** データ取得後にentitiesとapplication/editsに反映する */
  registEcouponNext: ac<TregistEcouponDone>('_registEcouponNext'),
};

// epics
const registEcoupon: Epic<
  AnyAction,
  WrapAction<typeof asyncActionWithCallback> | WrapAction<typeof _actions.registEcouponNext>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(ecouponCreateEvent.registEcoupon),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(couponServices.createCoupon(payload)),
        next: (v: ActionParameter<typeof _actions.registEcouponNext>) => _actions.registEcouponNext(v),
        complete: push('/mypage/ecoupon/create'),
      }),
    ),
  );

const registEcouponDone: Epic<
  AnyAction,
  WrapAction<typeof ecouponCreateEvent.loadEcouponCreateAggregation>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.registEcouponNext),
    map(() => ecouponCreateEvent.loadEcouponCreateAggregation()),
  );

const loadEcouponCreateAggregation: Epic<
  AnyAction,
  WrapAction<typeof asyncActionWithCallback> | WrapAction<typeof _actions.registEcouponNext>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(ecouponCreateEvent.loadEcouponCreateAggregation),
    map(() =>
      asyncActionWithCallback({
        asyncFunc: from(ecouponCreateAggregationServices.getDetail(authSelector.user(store.value).id)),
        next: ({ data }) => [ecouponCreateAggregationModule.actions.read([data])],
      }),
    ),
  );

export const ecouponCreateEpics = combineEpics(registEcoupon, registEcouponDone, loadEcouponCreateAggregation);

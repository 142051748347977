import { User } from 'project-domain/models/user';
import { profileCreateRepositories } from '~/repositories/profileCreate';

const createUser = async (user: User) => {
  const res = await profileCreateRepositories.save({
    id: user.id,
    mode: 'all',
    body: user,
    new: true,
  });

  if (res instanceof Error) {
    throw res;
  }

  return res;
};

export const profileCreateServices = {
  createUser,
};

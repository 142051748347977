import { combineReducers } from 'redux';
import { applicationEditsModule } from './edits';
import { applicationListsModule } from './lists';
import { applicationDetailModule } from './details';

const _applications = {
  edits: applicationEditsModule.reducer,
  list: applicationListsModule.reducer,
  details: applicationDetailModule.reducer,
};

export const applicationsReducers = combineReducers(_applications);

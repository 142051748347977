import React, { useContext } from 'react';

import cssStyles from './Loading.module.scss';
import { LoadingContext } from './LoadingProvider';

const Loading: React.FC<{}> = () => {
  const { isLoading } = useContext(LoadingContext);
  return !isLoading ? null : (
    <div className={cssStyles.loadingWrap}>
      <div className={cssStyles.loadingImg}>
        <img src="/images/img-loading.svg" alt="" />
      </div>
    </div>
  );
};

export default Loading;

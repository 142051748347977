import { curry, partial, partialRight } from 'global-lib/ramda';
import { matchPath, useLocation } from 'react-router-dom';
import { match } from 'global-lib/utils';
import { TPath, EPrivateRootPath, TPrivateRootPath, EPath } from '../lookups/router';

// パスパラメータ(「:◯◯Id」など)を「111」に置き換える関数
export const replacePathParamsToText = (path: string) => {
  const basePath = '111';
  return path
    .split('/')
    .map((v) => (v.includes(':') ? basePath : v))
    .join('/');
};

// <Link to={location => ({ ...location, pathname: "◯◯" })} /> のパスを取得する関数
export const getNextLocationPath = (to: string) => (location: object) => {
  const repalcedPath = replacePathParamsToText(to);
  return { ...location, pathname: repalcedPath };
};

const _toRegExp = (path: string): RegExp => {
  const regExpStr = `^${path.replace(/\/$/, '').replace(/:[A-Za-z0-9]+/g, '[A-Za-z0-9]+')}$`;
  return new RegExp(regExpStr);
};
const urlMatcher = (url: string, includes: string[], excludes: string[]): boolean => {
  const trimedUrl = url.replace(/(#|\?).*$/, '').replace(/\/$/, '');
  const incRegExps = includes.map((v) => _toRegExp(v));
  const excRegExps = excludes.map((v) => _toRegExp(v));
  return incRegExps.some((v) => v.test(trimedUrl)) && !excRegExps.some((v) => v.test(trimedUrl));
};
export const isUrlMatch = curry(urlMatcher);

export const isMatchPathname = (
  targetPathname: string,
  currentPathname: string,
  options?: { exact: boolean; strict: boolean },
) =>
  !!matchPath(currentPathname, {
    ...options,
    path: targetPathname,
  });

export const getPromptMessage = (
  message: string,
  paths: TPath[],
  stateKey: string,
  location: ReturnType<typeof useLocation>,
): true | string => {
  const showAlert = !(
    typeof location.state === 'undefined' ||
    !(location.state as any)[stateKey] ||
    paths.some((path) => isMatchPathname(path, location.pathname))
  );
  return showAlert ? message : true;
};

const isMatchRootPath = (path: string, rootPath: TPrivateRootPath): boolean =>
  new RegExp(`^${rootPath}/.*$`).test(path);

export const isPrivatePath = (path: string): boolean =>
  Object.values(EPrivateRootPath).some(partial(isMatchRootPath, [path]));

/**
 * 現在のパスからリダイレクト先のパスを取得する
 * @param pathname 現在のパス
 */
export const getRedirectPath = (pathname: string) =>
  match([[partialRight(isMatchRootPath, [EPrivateRootPath.top]), EPath.top]], pathname) || EPath.top;

import { ApiError } from 'global-lib/types/error';
import { TCredential, LoggedInResponse, TTOKEN } from '~/presentation/types';
import { authRepositories } from '~/repositories/auth';
import { EPath } from '~/presentation/lookups/router';

const INITIAL_LOGGED_IN_RESPONSE: LoggedInResponse = {
  result: false,
  id: '',
  accountId: '',
  firstName: '',
  lastName: '',
  token: '',
  exp: 0,
  isActivate: 2,
};

const login = async (credential: TCredential) => {
  const res = await authRepositories.login({
    condition: {},
    body: credential,
  });
  if (res instanceof Error) {
    throw res;
  }
  if (!res.result) {
    throw new ApiError(403, { code: 'E0003' });
  }
  return { ...INITIAL_LOGGED_IN_RESPONSE, ...res };
};

const logout = async () => {
  const res = await authRepositories.logout({
    condition: {},
    body: {},
  });

  if (res instanceof Error) {
    throw res;
  }
  return res;
};

const verifyToken = async (token: TTOKEN) => {
  const res = await authRepositories.verifyToken({
    condition: {},
    body: token,
  });

  if (res instanceof Error) {
    throw res;
  }
  return res;
};

const shouldAddResBody = (path: string): boolean => path === EPath.resetPasswordComplete || path.includes('mypage');
const loginAuto = async (): Promise<LoggedInResponse> => {
  const { pathname } = window.location;
  const res = await authRepositories.loginAuto({});
  if (res instanceof Error) {
    if (pathname.includes('mypage')) {
      window.location.href = EPath.login;
    }
    throw res;
  } else if (!res.result) {
    throw new ApiError(403, { code: 'E0002' });
  }
  if (pathname === EPath.login) {
    window.location.href = EPath.home;
  }
  return {
    ...INITIAL_LOGGED_IN_RESPONSE,
    ...(shouldAddResBody(pathname) ? res.body : {}),
  };
};

export const authServices = {
  login,
  logout,
  verifyToken,
  loginAuto,
};

import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, tap, mergeMap } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { from } from 'rxjs';
import { push } from 'connected-react-router';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { AppState } from '~/store';

import { resetPasswordEntryEvent } from '~/presentation/events';
import { resetpasswordentryService } from '~/application/services/resetpasswordentry';
import { errorActions } from '~/root/errorActions';
import { Logger } from '~/di';
import { applicationEditsModule } from '~/store/applications/edits';

type TSendEmailUserDone = ReturnTypeExcludePromise<typeof resetpasswordentryService.sendEmailToUser>;
const ac = actionCreatorFactory('[listener/pages/resetpasswordentry]');
const _actions = {
  sendEmailUserDone: ac<TSendEmailUserDone>('_sendEmailUserDone'),
};

const sendEmailToUser: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(resetPasswordEntryEvent.sendEmailToUser),
    map((payolad) =>
      asyncActionWithCallback({
        asyncFunc: from(resetpasswordentryService.sendEmailToUser(payolad.payload)),
        next: (v: ActionParameter<typeof _actions.sendEmailUserDone>) => [push('/resetpasswordentry/register/complete')　,　_actions.sendEmailUserDone(v)],
        error: (error: any) =>[errorActions.throwError(error)],
      }),
    ),
  );

const sendEmailUserDone: Epic<AnyAction, AnyAction, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.sendEmailUserDone),
    tap((payload) => Logger.log(payload)),
    mergeMap(({ payload }) => {
      return [applicationEditsModule.actions.sendEmail2User({ data: payload.body, meta: {} })];
    }),
  );

export const sendEmailToUserEpics = combineEpics(sendEmailToUser, sendEmailUserDone);

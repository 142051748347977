import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { configureStore } from '../store';
import history from './history';

import AppRouter from '../presentation/routers/AppRouter';
import { NotifierProvider } from '~/presentation/components/backgrounds/Notifier/NotifierProvider';
import { LoadingProvider } from '~/presentation/components/backgrounds/Loading/LoadingProvider';
import Notifier from '~/presentation/components/backgrounds/Notifier/Notifier';
import Loading from '~/presentation/components/backgrounds/Loading/Loading';
import DialogError from '~/presentation/components/organisms/DialogError/DialogError.container';
import AutoLogin from '~/presentation/components/backgrounds/AutoLogin/AutoLogin';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ScrollMemory = require('react-router-scroll-memory');

const store = configureStore({});

const App: React.FC = () => (
  <div className="App">
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <NotifierProvider>
          <LoadingProvider>
            <AutoLogin>
              <AppRouter />
            </AutoLogin>
            <Notifier />
            <Loading />
            <DialogError />
          </LoadingProvider>
        </NotifierProvider>
        <ScrollMemory />
      </ConnectedRouter>
    </Provider>
  </div>
);

export default App;

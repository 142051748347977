import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, mergeMap, tap } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { from } from 'rxjs';
import { push } from 'connected-react-router';
import { SNACKBAR_MESSAGE } from 'project-domain/lookups/';
import { AppState } from '~/store';
import { snackbarModule } from '~/store/ui/snackbar';
import { accountCreateEvent } from '~/presentation/events/pages/accountCreate';
import { accountCreateServices } from '~/application/services/account';
import { applicationEditsModule } from '~/store/applications/edits';
import { Logger } from '~/di';
import { authenticationsModule } from '~/store/auths/authentications';
import { loadingModule } from '~/store/ui/loading';
import { authSelector } from '~/application/selector/auth';

type TAccountCreateDone = ReturnTypeExcludePromise<typeof accountCreateServices.createAccount>;
type TReadAccountDone = ReturnTypeExcludePromise<typeof accountCreateServices.readAccount>;
const ac = actionCreatorFactory('[listener/pages/createAccount]');
const _action = {
  accountCreateNext: ac<TAccountCreateDone>('_accountCreateNext'),
  readAccountNext: ac<TReadAccountDone>('_readAccountNext'),
};

const readAccount: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(accountCreateEvent.readAccount),
    map(() =>
      asyncActionWithCallback({
        asyncFunc: from(accountCreateServices.readAccount(authSelector.user(store.value).id)),
        previous: loadingModule.actions.on(),
        next: (v: ActionParameter<typeof _action.readAccountNext>) => _action.readAccountNext(v),
        complete: [loadingModule.actions.off()],
      }),
    ),
  );

const readAccountDone: Epic<
  AnyAction,
  WrapAction<
    typeof applicationEditsModule.actions.updateRegistAccountData | typeof authenticationsModule.actions.updateAccountId
  >,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(_action.readAccountNext),
    tap(({ payload }) => Logger.log(payload)),
    mergeMap(({ payload }) => [
      applicationEditsModule.actions.updateRegistAccountData({ data: payload.data, meta: {} }),
      authenticationsModule.actions.updateAccountId({ accountId: payload.data?.id }),
    ]),
  );

const accountCreate: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(accountCreateEvent.registAccount),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(accountCreateServices.createAccount(authSelector.user(store.value).id, payload)),
        previous: loadingModule.actions.on(),
        next: (v: ActionParameter<typeof _action.accountCreateNext>) => _action.accountCreateNext(v),
        complete: [
          loadingModule.actions.off(),
          push('/mypage/setting'),
          snackbarModule.actions.enqueue({ message: SNACKBAR_MESSAGE.registDone }),
        ],
      }),
    ),
  );

const accountUpdate: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(accountCreateEvent.createUpdateAccount),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(accountCreateServices.createAccount(authSelector.user(store.value).id, payload, false)),
        next: (v: ActionParameter<typeof _action.accountCreateNext>) => _action.accountCreateNext(v),
        complete: [push('/mypage/setting'), snackbarModule.actions.enqueue({ message: SNACKBAR_MESSAGE.updateDone })],
      }),
    ),
  );

const accountCreateDone: Epic<
  AnyAction,
  WrapAction<
    typeof applicationEditsModule.actions.updateRegistAccountData | typeof authenticationsModule.actions.updateAccountId
  >,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(_action.accountCreateNext),
    tap(({ payload }) => Logger.log(payload)),
    mergeMap(({ payload }) => [
      applicationEditsModule.actions.updateRegistAccountData({ data: payload.body, meta: {} }),
      authenticationsModule.actions.updateAccountId({ accountId: payload.body.id }),
    ]),
  );

export const accountCreateEpics = combineEpics(
  accountCreate,
  accountCreateDone,
  accountUpdate,
  readAccount,
  readAccountDone,
);

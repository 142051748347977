import { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { asyncActionWithCallback } from 'global-lib/reduxObservableUtils';
import { from } from 'rxjs';
import { AppState } from '~/store';
import { logoutEvent } from '~/presentation/events';

import { authServices } from '~/application/services/auth';
import { loadingModule } from '~/store/ui/loading';
import { errorActions } from '~/root/errorActions';

const logout: Epic<AnyAction, AnyAction, AppState> = (action$, store) =>
  action$.pipe(
    ofAction(logoutEvent.logout),
    map(() =>
      asyncActionWithCallback({
        asyncFunc: from(authServices.logout()),
        previous: loadingModule.actions.on(),
        next: push('/'),
        error: (err: any) => errorActions.throwError(err),
        complete: loadingModule.actions.off(),
      }),
    ),
  );

export const logoutEpics = combineEpics(logout);

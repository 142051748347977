import { combineEpics, createEpicMiddleware } from 'redux-observable';
import {
  combineReducers,
  compose,
  applyMiddleware,
  StoreCreator,
  createStore,
  AnyAction,
  Dispatch,
  MiddlewareAPI,
} from 'redux';
import {
  routerMiddleware,
  connectRouter,
  getLocation,
  routerActions,
  LocationChangeAction,
  LOCATION_CHANGE,
} from 'connected-react-router';
import createSentryMiddleware from 'redux-sentry-middleware';
import * as Sentry from '@sentry/browser';
//
import { LibraryEpics } from 'global-lib/reduxObservableUtils';
import { EventListeners } from '~/application/listener';
import { authsReducers } from '~/store/auths';
import { isPrivatePath, getRedirectPath } from '~/presentation/helpers';
import { autoLoginSelector } from '~/application/selector/ui';
import { authSelector } from '~/application/selector/auth';
import history from '../root/history';
import { rootActions } from '../root/actions';
import { errorActions } from '../root/errorActions';

// reducers
import { entitiesReducers } from './entities';
import { applicationsReducers } from './applications';
import { uiReducers } from './ui';

// epics

export type AppState = ReturnType<typeof reducers>;

// reducer
const reducers = combineReducers({
  auths: authsReducers,
  entities: entitiesReducers,
  applications: applicationsReducers,
  ui: uiReducers,
  router: connectRouter(history),
});
const rootReducer = (state: any, action: any) => {
  if (action.type === rootActions.clearAllState.type) {
    // MEMO: 問題ないのでlinterをきる
    // eslint-disable-next-line  no-param-reassign
    state = undefined;
  }
  return reducers(state, action);
};

// epic
const rootEpic = combineEpics(EventListeners, LibraryEpics);
const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, AppState>();
const crashSentryReporter = (api: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: any) => {
  try {
    return next(action); // dispatch
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setExtra('Redux', { action, state: api.getState() });
      Sentry.captureException(err);
    });
    throw err; // re-throw error
  }
};

const isLocationChangeAction = (ac: any): ac is LocationChangeAction =>
  typeof ac.type !== 'undefined' && ac.type === LOCATION_CHANGE;

// auth
const authMiddleware = (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: any) => {
  const { pathname } = getLocation(store.getState());
  const isTriedAutoLogin = autoLoginSelector.isTried(store.getState());
  const isAuthenticated = authSelector.isAuthenticated(store.getState());

  if (!isTriedAutoLogin) {
    // 自動ログイン試行前はそのまま
    return next(action);
  }

  if (!isPrivatePath(pathname) || isAuthenticated) {
    // ログイン後の画面でない、または認証済みである場合、何もしない
    return next(action);
  }

  if (isLocationChangeAction(action) && !isPrivatePath(action.payload.location.pathname)) {
    // 遷移先画面がログイン後の画面でない
    return next(action);
  }

  // tokenがない場合、ログイン画面に遷移する
  const redirectPath = getRedirectPath(pathname);
  setTimeout(() => {
    // MEMO: routerActions.replaceより少しずらして実行させないとmessage表示できなかったため
    next(errorActions.throwError({ code: 'E0002' }));
  }, 50);
  return next(routerActions.replace(redirectPath));
};

// enhance
const enhancers = compose(
  applyMiddleware(
    authMiddleware,
    epicMiddleware,
    routerMiddleware(history),
    createSentryMiddleware(Sentry),
    crashSentryReporter,
  ),
  (window as any).__REDUX_DEVTOOLS_EXTENSION__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    : (f: StoreCreator) => f,
);

export const configureStore = (initialState: any) => {
  const store = createStore(rootReducer, initialState, enhancers);
  epicMiddleware.run(rootEpic);
  return store;
};

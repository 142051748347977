import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Epic, combineEpics } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { map, mergeMap } from 'rxjs/operators';
import { WrapAction, asyncActionWithCallback, ActionParameter } from 'global-lib/reduxObservableUtils';
import { ReturnTypeExcludePromise } from 'global-lib/types';
import { from } from 'rxjs';
import { AppState } from '~/store';
import { contactEvent } from '~/presentation/events';
import { applicationEditsModule } from '~/store/applications/edits';
import { contactServices } from '~/application/services/contact';

// _actions
type TregistContactDone = ReturnTypeExcludePromise<typeof contactServices.createContact>;
const ac = actionCreatorFactory('[listener/pages/contact]');
const _actions = {
  /** データ取得後にentitiesとapplication/editsに反映する */
  registContactNext: ac<TregistContactDone>('_registContactNext'),
};

// epics
const registContact: Epic<
  AnyAction,
  WrapAction<typeof asyncActionWithCallback> | WrapAction<typeof _actions.registContactNext>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(contactEvent.registContact),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFunc: from(contactServices.createContact(payload)),
        next: (v: ActionParameter<typeof _actions.registContactNext>) => _actions.registContactNext(v),
      }),
    ),
  );

const registContactDone: Epic<
  AnyAction,
  WrapAction<typeof applicationEditsModule.actions.updateRegistContactData>,
  AppState
> = (action$, store) =>
  action$.pipe(
    ofAction(_actions.registContactNext),
    mergeMap(({ payload }) => [
      applicationEditsModule.actions.updateRegistContactData({
        data: { ...payload.body, title: '', content: '' },
        meta: {},
      }),
    ]),
  );

export const contactEpics = combineEpics(registContact, registContactDone);

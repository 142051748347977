import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory('[events/pages/resetpasswordentry]');
type Email = {
  email: string;
};
const actions = {
  sendEmailToUser: ac<Email>('resetpasswordentry'),
};

export const resetPasswordEntryEvent = actions;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './presentation/styles/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './root/App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://24a33ee33ec24586a5328632be02268d@o447547.ingest.sentry.io/5427539',
  environment: process.env.REACT_APP_ENV_TYPE || 'local',
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
